/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import EventModel from '../../../utils/tracking/models';
import pressboard from '../../../client/utils/third_party_trackers/pressboard';
import { isAdvertorial, isWciAwards } from '../advertorial/advertorial';
import Sticky from 'react-stickynode';
import { UserConsumer } from '../../components/context/userContext';
import { Waypoint } from 'react-waypoint';
import isRedesignedArticle from '../../../utils/isRedesignedArticle';

import loadable from '@loadable/component';

const Body = loadable(() => import('../post/content/body'));
const Sidebar = loadable(() => import('../post/content/sidebar'));
const PostHero = loadable(() => import('./layouts'), {
  resolveComponent: components => components.PostHero
});
const PostShort = loadable(() => import('./layouts'), {
  resolveComponent: components => components.PostShort
});
const PostVideo = loadable(() => import('./layouts'), {
  resolveComponent: components => components.PostVideo
});
const PostDefault = loadable(() => import('./layouts'), {
  resolveComponent: components => components.PostDefault
});
const ListArticle = loadable(() => import('./layouts'), {
  resolveComponent: components => components.ListArticle
});
const GiftGuide = loadable(() => import('./layouts'), {
  resolveComponent: components => components.GiftGuide
});
const EventVideo = loadable(() => import('./layouts'), {
  resolveComponent: components => components.EventVideo
});
const PhotoEssay = loadable(() => import('./layouts'), {
  resolveComponent: components => components.PhotoEssay
});
const VerticalPoster = loadable(() => import('./layouts'), {
  resolveComponent: components => components.VerticalPoster
});
const ArticleRedesign = loadable(() => import('./layouts'), {
  resolveComponent: components => components.ArticleRedesign
});
const CerosEmbed = loadable(() => import('./layouts'), {
  resolveComponent: components => components.CerosEmbed
});
const BWI2020 = loadable(() =>
  import('../../containers/pages/list_landers/bwi-2020')
);
const IBD2020 = loadable(() =>
  import('../../containers/pages/list_landers/ibd-2020')
);
const MIC2021 = loadable(() =>
  import('../../containers/pages/list_landers/mic-2021')
);
const MostCreativePeopleArticle = loadable(() => import('./layouts/mcp'));
const AdContainer = loadable(() => import('../ad/AdContainer'));
const InnovationFestivalAdvertorialBanner = loadable(() =>
  import('./content/innovation_festival_banner')
);

export default class PostsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyOffset: 109
    };

    this.meterTracked = false;
    this.postId = get(props.posts, '[0].id', null);
    this.specialRedesignArticle = isRedesignedArticle(this.postId);
  }

  componentDidMount() {
    this.rendered = true;
    this.renderTrackers();
    // let compensateForNewsletter;
    // if (!window.localStorage.getItem('hide-newsletter-signup-topnav')) {
    //   this.setState({compensateForNewsletter: true})
    //   compensateForNewsletter = true;
    //   // this.onRemove(this.getNewsLetter(),() => {this.setState({compensateForNewsletter: false})})
    // }
  }

  shouldComponentUpdate() {
    const { props, rendered } = this;
    // initialPageLoad is needed for the first post to enable sticky ads.
    const isInitLoad = get(props, 'config.initialPageLoad');

    return !rendered || isInitLoad;
  }

  hasAdvertorialBar(tags) {
    const slugs = tags.map(tag => tag.slug);

    if (slugs.includes('_advertorial_festival')) {
      return {
        tag: '_advertorial_festival',
        background: '#ffffff',
        color: '#5f5f5f',
        rule: '#cccccc',
        image: {
          url:
            'https://images.fastcompany.net/image/upload/v1663940622/fcweb/FCIF-Logo-FCWorks-2022_n1kris.svg',
          width: '405px'
        }
      };
    }

    if (slugs.includes('_advertorial_mic_summit')) {
      return {
        tag: '_advertorial_mic_summit',
        background: '#000000',
        color: '#ffffff',
        image: {
          url:
            'https://assets.fastcompany.com/image/upload/v1652800031/fcweb/micsummit_fascoworks-logo_2022.svg',
          width: '331px'
        }
      };
    }

    return null;
  }

  renderTrackers() {
    const postId = get(this.props.posts, '[0].id', 0);
    if (postId === 40504669 || postId === 40537786) {
      pressboard();
    }
  }

  renderTrackerField() {
    const { posts } = this.props;

    const customScriptData = get(posts, '[0].customFields.custom_script');
    const customScriptExpiration = get(posts, '[0].customFields.expiration');

    if (customScriptData && customScriptExpiration === false) {
      return <div dangerouslySetInnerHTML={{ __html: customScriptData }} />;
    }
    return '';
  }

  renderTopAds(firstPost, isList) {
    if (isList) return false;

    const { isFirstPost, pageNum } = this.props;
    const fullPageCeros = get(
      firstPost,
      'customFields.full_page_ceros_embed',
      false
    );

    const region = isFirstPost
      ? 'ad-wrapper--articletop'
      : 'ad-wrapper--articlescroll';

    return (
      <div className="post__article-top-ad-wrapper top-hero">
        <AdContainer
          region={region}
          type={isFirstPost ? 'hero' : 'break'}
          page={isFirstPost ? 1 : 2}
        />
      </div>
    );
  }

  renderVerticalTemplate(advertorial) {
    const { posts } = this.props;
    const post = get(posts, '[0]', {});

    return (
      <VerticalPoster {...this.props}>
        {
          /* // Children Start */
          <div className="post post--hero post--verticalPoster">
            <div className="post__main">
              <Body
                {...this.props}
                post={post}
                isLongPost={true}
                hasNewsletter={true}
                fullPageAdvertorial={advertorial}
                getAdhesiveHeight={this.props.getAdhesiveHeight}
              />
            </div>
            <Sidebar
              {...this.props}
              post={post}
              fullPageAdvertorial={advertorial}
              isLongPost={true}
            />
          </div>
          /* // Children End */
        }
      </VerticalPoster>
    );
  }

  renderArticleRedesign(advertorial) {
    const { posts } = this.props;
    const post = get(posts, '[0]', {});
    post.layout = 'redesign'; // So we don't render a 2nd header when inside the Body component

    return (
      <ArticleRedesign {...this.props}>
        {
          <div className="post post--hero post--redesign">
            <div className="post__main">
              <Body
                {...this.props}
                post={post}
                isLongPost={true}
                hasNewsletter={true}
                fullPageAdvertorial={advertorial}
                specialRedesignArticle={this.specialRedesignArticle}
              />
            </div>
            <Sidebar
              {...this.props}
              post={post}
              fullPageAdvertorial={advertorial}
              isLongPost={true}
            />
          </div>
        }
      </ArticleRedesign>
    );
  }

  renderTemplate(firstPost, advertorial, isList, meter) {
    const isMCP = get(firstPost, 'customFields.show_mcp_list', false);
    const isGiftGuide = get(firstPost, 'customFields.show_gift_guide', false);
    const isPhotoEssay = get(firstPost, 'customFields.show_photo_essay', false);
    const isVerticalPosterImageTemplate = get(
      firstPost,
      'customFields.verticalPosterImage',
      false
    );

    if (this.props.event && firstPost.playerScriptId) {
      return (
        <EventVideo
          {...this.props}
          event={this.props.event}
          advertorial={advertorial}
        />
      );
    } else if (isList) {
      if (firstPost.customFields && firstPost.customFields.select_the_list) {
        switch (firstPost.customFields.select_the_list) {
          case 'mic2021':
            return <MIC2021 {...this.props} advertorial={advertorial} />;
          case 'bwi2020':
            return <BWI2020 {...this.props} advertorial={advertorial} />;
          case 'ibd2020':
            return <IBD2020 {...this.props} advertorial={advertorial} />;
          default:
            return <ListArticle {...this.props} advertorial={advertorial} />;
        }
      } else {
        return <ListArticle {...this.props} advertorial={advertorial} />;
      }
    } else if (isMCP) {
      return (
        <MostCreativePeopleArticle {...this.props} advertorial={advertorial} />
      );
    } else if (isGiftGuide) {
      return <GiftGuide {...this.props} advertorial={advertorial} />;
    } else if (isVerticalPosterImageTemplate) {
      return this.renderVerticalTemplate(advertorial);
    } else if (isPhotoEssay) {
      return <PhotoEssay {...this.props} advertorial={advertorial} />;
    } else if (this.specialRedesignArticle) {
      return this.renderArticleRedesign(advertorial);
    } else {
      // STYLE GUIDE POST: /pvw/40400521/style-guide-for-fast-company-posts-in-2017
      switch (firstPost.layout) {
        // Poster: 3021050/leadership-lessons-from-the-us-army-in-afghanistan
        // or Slideshow:
        // or Video:
        case 'hero':
          return <PostHero {...this.props} advertorial={advertorial} />;
        // 40538300/facebook-comes-to-the-realization-that-one-feed-is-better-than-two
        case 'short':
          return <PostShort {...this.props} advertorial={advertorial} />;
        // video/your-jean-jacket-of-the-future-could-be-made-using-3d-printing-tech/cc5Qb4Nr
        case 'video':
          return <PostVideo {...this.props} advertorial={advertorial} />;
        // Poster: 40525480/the-marvel-studios-mind-set-for-making-hit-after-hit
        // Slideshow: 40537294/the-25-best-productivity-apps-for-2018
        default:
          return (
            <PostDefault
              {...this.props}
              advertorial={advertorial}
              meter={meter}
              specialRedesignArticle={this.specialRedesignArticle}
            />
          );
      }
    }
  }

  render() {
    const { posts, event } = this.props;
    const firstPost = get(posts, '[0]');
    const fullPageCeros = get(
      firstPost,
      'customFields.full_page_ceros_embed',
      false
    );

    const cerosEmbed = get(firstPost, 'customFields.ceros_embed', false);
    const advertorial = isAdvertorial(get(firstPost, 'tags', []));
    const wciAwards = isWciAwards(get(firstPost, 'tags', []));
    const advertorialBar = this.hasAdvertorialBar(get(firstPost, 'tags', []));
    const modifier = advertorial ? ' post__wrapper--advertorial' : '';
    const isMCP = get(firstPost, 'customFields.show_mcp_list', false);

    if (!firstPost) return null;

    let brandWorksLogo =
      'https://assets.fastcompany.com/asset_files/static/svg/FastCoWorks_branded_web_article_slug.svg';
    let logoImageClass = '';
    if (wciAwards) {
      brandWorksLogo =
        'https://images.fastcompany.net/image/upload/v1620841514/fcweb/WCI_FC_logo_small_black_noType_fwvn6a.svg';
      logoImageClass = 'wciLogo';
    }

    const isList = !!(
      firstPost &&
      firstPost.customFields &&
      ((firstPost.customFields.list_article &&
        firstPost.customFields.list_article.is_list_article) ||
        firstPost.customFields.is_list)
    );

    const ceros = get(firstPost, 'customFields.ceros_embed', null);
    const cerosRegex = /<iframe .+ style=("|').+(width|min-width):\s?100%/im;
    const fullWidthCeros =
      ceros && cerosRegex.test(ceros) && ceros.includes('ceros.com');

    if (event) {
      return <div>{this.renderTemplate(firstPost, advertorial, isList)}</div>;
    }

    return (
      <div
        className={`post__wrapper${modifier}${
          this.specialRedesignArticle ? ' redesign' : ''
        }`}
        id={`post__wrapper-${firstPost.id}`}
      >
        {this.renderTopAds(firstPost, isList)}
        <UserConsumer>
          {({ meter, showOffer, journey, isLoggedIn }) => { // eslint-disable-line
            const maxMeteredViews = get(meter, 'maxViews', 0);
            const articlesLeft = get(meter, 'viewsLeft', undefined);

            return (
              <React.Fragment>
                {!isLoggedIn &&
                  !this.props.event && (
                    <React.Fragment>
                      <Waypoint
                        onEnter={() => {
                          if (!this.meterTracked) {
                            if (!isEmpty(meter)) {
                              this.meterTracked = true;
                              const modernEvent = new EventModel(
                                `Meter Display`,
                                `Piano Meter Remaining Articles - grey`,
                                `Display`,
                                `Display`,
                                `${articlesLeft} | ${maxMeteredViews}`
                              );
                              modernEvent.addTrackingAttributes({});
                              modernEvent.triggerTrackingEvent(
                                'meter-display',
                                modernEvent.getTrackingObject()
                              );
                            }
                          }
                        }}
                      />
                      {/* <ProgressMeter
                        numLeft={articlesLeft}
                        meterData={meter}
                        meterClick={() => {
                          showOffer(meterOffer, meterTemplate, 'modal');
                        }}
                        ctaText={meterCtaText}
                        firstPost={firstPost}
                      /> */}
                    </React.Fragment>
                  )}
              </React.Fragment>
            );
          }}
        </UserConsumer>

        {advertorialBar && <InnovationFestivalAdvertorialBanner />}

        {!advertorialBar &&
          (advertorial || wciAwards) && (
            <div className="advertorial-header-wrapper">
              <Sticky
                top={this.state.stickyOffset}
                bottomBoundary={`.sticky-boundry-${posts[0].id}`}
                enabled={false}
                activeClass="sticky"
                releasedClass="sticky__released"
              >
                <div className="advertorial-mobile-paid">
                  <div className="advertorial-mobile-paid__disclaimer">
                    PAID CONTENT
                  </div>
                </div>
                <div
                  className={`advertorial-border ${
                    fullWidthCeros ? 'noMargin' : ''
                  }`}
                >
                  <div className="advertorial-header">
                    <div className="advertorial-header__inner">
                      <div className="advertorial-header__brandworks-logo">
                        <img
                          height="30"
                          width="140"
                          className={logoImageClass}
                          src={brandWorksLogo}
                          alt="hey"
                        />
                        {wciAwards && (
                          <span className="wciText">WORLD CHANGING IDEAS</span>
                        )}
                      </div>
                      {wciAwards ? (
                        <div className="advertorial-header__paid">
                          <span>IN PARTNERSHIP WITH</span>
                          <img
                            className="genpactLogo"
                            src="https://images.fastcompany.net/image/upload/v1620841514/fcweb/Genpact_horizontal_color_rgb_dagofu.svg"
                            alt="genpact logo"
                          />
                        </div>
                      ) : (
                        <div className="advertorial-header__paid">
                          PAID CONTENT
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Sticky>
            </div>
          )}

        {fullPageCeros && cerosEmbed ? (
          <CerosEmbed {...this.props} embedCode={cerosEmbed} />
        ) : (
          <UserConsumer>
            {({ meter }) =>
              this.renderTemplate(firstPost, advertorial, isList, isMCP, meter)
            }
          </UserConsumer>
        )}
        {this.renderTrackerField()}
        <span className={`sticky-boundry-${posts[0].id}`} />
      </div>
    );
  }
}

PostsContainer.defaultProps = {
  isDesktop: false,
  isFirstPost: false,
  related: [],
  live: false,
  event: null,
  getAdhesiveHeight: () => 0,
  setSecondScrollWasMounted: undefined
};

PostsContainer.propTypes = {
  config: PropTypes.shape({}).isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  related: PropTypes.arrayOf(PropTypes.shape({})),
  isDesktop: PropTypes.bool,
  isFirstPost: PropTypes.bool,
  pageNum: PropTypes.number.isRequired,
  live: PropTypes.bool,
  event: PropTypes.string,
  getAdhesiveHeight: PropTypes.func,
  setSecondScrollWasMounted: PropTypes.func
};
